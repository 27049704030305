.content-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 90%; /* Increase the width to take up more of the screen */
  max-width: 1200px; /* Set a max width if needed */
  width: 100%;
  min-height: 640px;

  /* responsive */

  padding: 48px;
}

.content__title {
  margin-top: 10;

  color: var(--CRELYTICA-DARKEST-BLUE);
}

.content__body {
  /* responsive */
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word; /* Recommended for handling word breaks in long words */

  /* Ensures container takes minimum necessary width, might help in certain layouts */
  display: inline-block;
  white-space: normal; /* Ensures text wraps at spaces */
}

#page-description-lease-upload {
  display: flex;
  flex-direction: column;  
  margin-bottom: 2em;
}

#page-description {
  display: flex;
  flex-direction: column;  
}

#page-description span {
  margin-bottom: 1.6rem;
}

#page-description span:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .content-layout {
    min-height: 300px;
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}

.header-with-button {
  display: flex;
  justify-content: space-between; /* Aligns children (h2 and button) on opposite ends */
  align-items: center; /* Centers items vertically, to ensure they are aligned horizontally */
  margin-bottom: 1rem;
}

/* Ensure the button does not stretch to fill the flex container */
.download__button {
  white-space: nowrap; /* Prevents the text within the button from wrapping */
}

.content__body span {
  /* Directly ensure span content wraps */
  white-space: normal;
}

.content_text {
  display: flex;
  flex-direction: column;  
  color: var(--CRELYTICA-DARKEST-BLUE);
}

.content_text span {
  margin-bottom: 1.6rem;
}

.content_text span:last-child {
  margin-bottom: 0;
}