@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap");

/* Theme */

:root {
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --off-white: #c8d7ea;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --grey: #777;
  --black: #000000;
  --yellow: #ebca40;
  --highlight: rgb(248, 248, 174);
  --highlight-green:#218838;
  --highlight-red:#ff0000;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #0367a6;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;
  --CRELYTICA-DARKEST-BLUE: #021526;
  --CRELYTICA-DARK-BLUE: #022340;
  --CRELYTICA-BLUE: #024873;
  --CRELYTICA-LIGHT-BLUE: #0367a6;
  --CRELYTICA-GREY-BLUE: #58788c;
  --CRELYTICA-RED: #a6380c;
  --CRELYTICA-YELLOW: #f2c41d;
  --CRELYTICA-GREEN: #2b5336;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  /*--font-secondary: "Space Grotesk", sans-serif;*/
  --font-secondary: "Montserrat", sans-serif;
  --font-mono: "Fira Code", monospace;
}
