  
  .sticky-filter {
    position: sticky;
    z-index: 300;
    background-color: #fff;
    top: 80px;    
  }

  .filters-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px; /* Spacing between child elements */
    margin: 10px 0 0 0; /* Consistent margin between containers */
    padding: 10px;
    background-color: var(--light-aluminium);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--aluminium);
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px; /* Spacing between child elements */
    margin: 5px 0; /* Consistent margin between containers */
    padding: 10px;
    background-color: var(--light-aluminium);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--aluminium);
  }

  .filters-wrapper, .nav-wrapper {
    display: flex;
    align-items: stretch; /* Ensures both the label and the section match height */
}

.vertical-label-filters {
    writing-mode: vertical-rl; /* Rotate the text vertically */
    text-orientation: mixed; /* Ensure proper character orientation */
    margin-right: 10px; /* Space between the label and the section */
    font-weight: bold;
    color:  #f8f9fa; /* Adjust this color as needed */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the text vertically */
    font-size: 10px;
    padding: 10px;
    background-color: var(--CRELYTICA-DARK-BLUE);
    border-radius: 10px; /* Rounded corners */
    margin: 10px 5px 0px 0px; /* Consistent margin between containers */
    width: 30px; /* Set a fixed width for both labels */
}

.vertical-label-navigation {
    writing-mode: vertical-rl; /* Rotate the text vertically */
    text-orientation: mixed; /* Ensure proper character orientation */
    margin-right: 10px; /* Space between the label and the section */
    font-weight: bold;
    color:  #f8f9fa; /* Adjust this color as needed */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the text vertically */
    font-size: 10px;
    padding: 10px;
    background-color: var(--CRELYTICA-DARK-BLUE);
    border-radius: 10px; /* Rounded corners */
    margin: 5px 5px 5px 0px; /* Consistent margin between containers */
    width: 30px; /* Set a fixed width for both labels */
}

.filters-container, .navigation-buttons {
    flex-grow: 1; /* Ensure the main content grows to fill the remaining space */
}
  
  .nav-group {
    display: flex;
    align-items: center;
    flex-direction: row; /* Change from column to row */
    gap: 10px; /* Add spacing between the buttons */
  }
  
  .nav-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .navigation-buttons button {
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: var(--CRELYTICA-GREEN);
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .navigation-buttons button:hover {
    background-color:  var(--highlight-green);
  }
  
  .navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .delete-group button {
    background-color: var(--CRELYTICA-RED);
  }
  
  .delete-group button:hover {
    background-color: var(--highlight-red);
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .filter-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filter-group select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
  }
