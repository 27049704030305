.modal-textarea {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allows vertical resizing */
  min-height: 100px; /* Minimum height to make the textarea more prominent */
}

.modal-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button-group button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--grey);
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.button-group button:hover {
  background-color: var(--CRELYTICA-DARK-BLUE);
}

.modal-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  justify-content: center;
  align-items: center; /* Vertically center (if needed) */
}


.modal-button-incorrect, .modal-button-submit, .modal-button-correct, .modal-button-review, .modal-button-change, .modal-button-select {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.modal-button-select, .modal-button-review {
  justify-content: center;
  align-items: center; /* Vertically center (if needed) */
  max-width: 200px;
}

.modal-button-incorrect {
  background-color: var(--CRELYTICA-RED);
}

.modal-button-incorrect:hover {
  background-color: var(--highlight-red);
}

.modal-button-submit, .modal-button-correct, .modal-button-review {
  background-color: var(--CRELYTICA-GREEN);
}

.modal-button-submit:hover, .modal-button-correct:hover, .modal-button-review:hover {
  background-color: var(--highlight-green);
}

.modal-button-change {
  background-color: var(--CRELYTICA-GREY-BLUE);
}

.modal-button-change:hover {
  background-color: var(--CRELYTICA-DARK-BLUE);
}

.modal-button-select {
  background-color: var(--CRELYTICA-DARK-BLUE);
}

.modal-button-select:hover {
  background-color: var(--CRELYTICA-DARKEST-BLUE);
}



.modal-section label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.modal-section {
  margin-bottom: 20px;
}


.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: var(--CRELYTICA-RED);
}

.modal-close:hover {
  color: var(--highlight-red);
}

.enhanced-modal {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enhanced-modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
}

.modal-section {
  margin-bottom: 20px;
}

.modal-section label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between elements */
}

.input-group select,
.input-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1; /* Allows select and input to grow equally */
}

.input-group .or-text {
  margin: 0;
  font-style: italic;
  white-space: nowrap; /* Prevents text from wrapping */
}


.label-and-definition {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-and-definition label {
  font-weight: bold;
}

.definition-text-upload {
  font-weight: normal;
  color: #666;
  font-size: 0.9rem;
  text-align: right;
  max-width: 54%;
}