.image-viewer {
  flex: 1;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow: auto; /* Enable scrolling */
  border: 1px solid #ccc; /* Optional: Add a border */
  z-index: 100;
}

.image-viewer img {
  width: 100%;
  height: auto;       /* Preserve aspect ratio */
  max-width: 100%;    /* Prevent overflow */
  display: block;
  z-index: 100;
}

@media only screen and (max-width: 768px) {
  /* Make both containers full width */
  .image-viewer {
    height: auto !important;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;    /* Hide horizontal scroll if needed */
    overflow-y: auto;     /* If you want vertical scroll */
    max-height: none;     /* Let it grow if needed */
  }

  .image-viewer img {
    width: 100%;     /* Fill container’s width */
    height: auto;    /* Keep aspect ratio */
    max-width: 100%; /* Prevent overflow on narrow devices */
    display: block;
  }
}

.document-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: var(--CRELYTICA-DARK-BLUE);
  border-radius: 10px; /* Rounded corners */
  margin: 0px 0 10px 0; /* Consistent margin between containers */

}

.document-name {
  font-size: 14px;
  font-weight: bold;
  color:  #f8f9fa; /* Use your theme color */
  max-width: 65%;
}

.page-number {
  font-size: 14px;
  font-weight: bold;
  color:  #f8f9fa; /* Use your theme color */
}

/* Style for the 'Percent Verified' label text */
.percent-verified-label {
  font-size: 14px;
  font-weight: bold;
  color: #f8f9fa; /* Always white */
}

/* Style for the percentage value itself */
.percent-verified-value {
  font-size: 14px;
  font-weight: bold;
}

/* Red color for when the percent is not 100% */
.percent-verified-value.red {
  color: #ff4d4f; /* A softer red for better contrast */
}

/* Green color for when the percent is 100% */
.percent-verified-value.green {
  color: #28a745; /* Green for 100% verified */
}