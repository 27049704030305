.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.button {
  border: 0;
  border-radius: 0.8rem;

  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--white);

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  min-width: 10rem;
  padding: 1.6rem 1.6rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button--compact {
  /* responsive */

  padding: 1rem 1.6rem;

  font-size: 1.6rem;
  line-height: 1.6rem;
}

.button--primary {
  background-color: var(--CRELYTICA-LIGHT-BLUE);
}

.button--primary:hover {
  background: var(--CRELYTICA-GREEN);
}

.button--secondary {
  background-color: var(--white);
  color: var(--black);
}

.button--secondary:hover {
  background: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 480px) {
  .button {
    /* responsive */

    min-width: 0.8rem;
    padding: 1rem 1.2rem;

    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .button--compact {
    /* responsive */

    padding: 0.6rem 1rem;

    font-size: 1.3rem;
    line-height: 2rem;
  }
}

.button__login,
.button__logout {
  min-width: 8.4rem;
  max-width: 20rem;

  border: 0.1rem solid var(--CRELYTICA-LIGHT-BLUE);
  color: var(--white);
  background: var(--CRELYTICA-LIGHT-BLUE);
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.feedback-button {
  padding: 8px 16px;
  font-size: 16px;
  background: var(--CRELYTICA-GREEN);
  color: var(--white);
  border: 0.1rem solid var(--CRELYTICA-GREEN);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 10PX;
}


.feedback-button:hover {
  background-color: var(--highlight-green);
  border: 0.1rem solid var(--highlight-green);
}


.search-button {
  padding: 8px 16px;
  font-size: 16px;
  background: var(--CRELYTICA-LIGHT-BLUE);
  color: var(--white);
  border: 0.1rem solid var(--CRELYTICA-LIGHT-BLUE);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 10PX;
}


.search-button:hover {
  background-color: var(--CRELYTICA-DARK-BLUE);
  border: 0.1rem solid var(--CRELYTICA-DARK-BLUE);
}

.upload__button,
.switch__button {
  min-width: 8.4rem;
  max-width: 20rem;

  border: 0.1rem solid var(--CRELYTICA-LIGHT-BLUE);
  color: var(--white);
  background: var(--CRELYTICA-LIGHT-BLUE);
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}


.button__sign-up {
  min-width: 8.4rem;

  border: 0.1rem solid var(--white);
  color: var(--white);
  background-color: transparent;
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-style: normal;
  font-weight: 500;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;
}

.button__login:last-child,
.button__logout:last-child,
.button__sign-up:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 641px) {
  .button__login,
  .button__logout,
  .button__sign-up {
    padding: 0.8rem 1.2rem;

    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.data_room_upload__button,
.download__button {
  min-width: 8.4rem;
  max-width: 20rem;

  border: 0.1rem solid var(--CRELYTICA-DARKEST-BLUE);
  color: var(--white);
  background: var(--CRELYTICA-DARKEST-BLUE);
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.review__button {
  min-width: 8.4rem;
  max-width: 20rem;

  border: 0.1rem solid var(--CRELYTICA-DARKEST-BLUE);
  color: var(--white);
  background: var(--CRELYTICA-DARKEST-BLUE);
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.download__button-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.download-icon {
  width: 48px; /* Adjust size as needed */
  height: 48px;
  display: inline-block;
  vertical-align: middle;
}
