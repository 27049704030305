.page-footer {
  width: 100%;
  margin-top: 2.4rem;
  background-color: var(--dark-aluminium); /* Adjust this color as needed */
  color: var(--light-aluminium);
  text-align: center;
  padding: 1rem 0; /* Adjust padding to control the height of the footer */
}
/* Initially hide the forced breaks on larger screens */
.mobile-break {
  display: none;
}

/* On screens <= 640px, show the breaks (block or inline-block). */
@media (max-width: 640px) {
  .mobile-break {
    display: block;
  }

  /* Ensure the entire paragraph is centered on mobile. */
  .footer-cc-license {
    display: inline-block !important; /* override inline-flex if needed */
    text-align: center !important;
  }
}