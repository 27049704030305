.content-layout {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.lease-select {
    padding: 8px;
    margin-bottom: 20px;
    font-size: 16px;
}

.search-results-container {
    flex: 1;
    overflow-y: auto;
    max-width: 40%;
    max-height: 95vh;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
}

@media only screen and (max-width: 768px) {
    .search-results-container {
      max-width: 100%;
      width: 100%;
      max-height: none; /* Allow it to grow as needed */
    }
  }

.search-input-container {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;/* This is the combined height of the page header and the filter */
    z-index: 200;
    display: flex;
    margin-bottom: 20px;
}

.search-input {
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.highlighted-clause {
    background-color:  rgb(248, 248, 174) !important;
  }

.search-button:hover {
    background-color: #0056b3;
}

.search-results {
    margin-top: 20px;
}

.result-list {
    list-style-type: none;
    padding: 0;
}

.result-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.result-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.result-subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.result-text {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
}

.no-results {
    font-size: 16px;
    color: #888;
    text-align: center;
}
