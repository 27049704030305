/* General Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  
  font-family: var(--font-primary);
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}

/* Table Headers & Table Rows and Cells */
.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table td {
  font-size: 0.9rem;
}

/* Table Headers */
.table th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;/* This is the combined height of the page header and the filter */
  z-index: 200;
  padding-top: 5px;
  font-size:1.3rem;
  padding-bottom: 5px;
  text-align: left;
  background-color: var(--CRELYTICA-DARK-BLUE);
  color: white;
}

@media only screen and (max-width: 600px) {
  .table, .table-container {
    width: 100%;
    max-height: 90vh;
    display: block;
    overflow-x: auto;
    overflow-y: auto;
  }
}

/* First Column Styling (no border on the left) */
.table td:first-child, .table th:first-child {
  border-left: none;
}

/* Last Column Styling (no border on the right) */
.table td:last-child, .table th:last-child {
  border-right: none;
}

/* Row Hover State */
.table tr:hover {
  background-color: var(--off-white);
}

 
  
.table-container {
  position: relative;
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
}


.scroll-left, .scroll-right {
  margin: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  
  
  min-width: 8.4rem;
  padding: 8px 16px;
  font-size: 16px;
  background: var(--CRELYTICA-LIGHT-BLUE);
  color: var(--white);
  border: 0.1rem solid var(--CRELYTICA-LIGHT-BLUE);
  border-radius: 0.8rem;
  cursor: pointer;
}
.scroll-left:hover, .scroll-right:hover {
  background-color: var(--CRELYTICA-DARK-BLUE);
  border: 0.1rem solid var(--CRELYTICA-DARK-BLUE);
}

.scroll-left {
  left: 0;
}

.scroll-right {
  right: 0;
}

/* Striped Rows: Alternating row colors can improve readability. */
tr:nth-child(even) {
  background-color: var(--aluminium);
}


.highlighted-variable {
  background-color: var(--highlight) !important;
  border: 2px solid  var(--black);
}


.variable-results-container {
  flex: 1;
  overflow-y: auto;
  max-width: 42%;
  max-height: 95vh;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
}

@media only screen and (max-width: 768px) {
  /* Make both containers full width */
  .variable-results-container {
    max-width: 100%;
    width: 100%;
    max-height: none;          /* let them grow naturally */
  }
  .scroll-left,
  .scroll-right {
    display: none;
  }
}

.variable-item {
  width: 100%;
  padding: 15px;
  border: 1px solid  var(--off-white);
  border-radius: 8px;
  background-color:  var(--white);
  position: relative;
}

.variable-item:hover {
  background-color: var(--off-white);
}

.variable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variable-answer {
  flex: 1;
  overflow-y: auto;
  font-size: 1.5em;
  margin: 0;
  max-width: 90%;
  min-height: 7vh;
  max-height: 35vh;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
}

.variable-category {
  font-size: 1em;
  margin: 0;
}

.variable-verified.red {
  font-size: 1em;
  color: var(--CRELYTICA-RED);
}

.variable-verified.gray {
  font-size: 1em;
  color:  var(--grey);
}

.variable-title {
  font-size: 1.2em;
  margin: 10px 0;
}

.variable-question {
  font-size: 1em;
  color:  var(--grey);
}

.variable-reason, .variable-feedback {
  flex: 1;
  overflow-y: auto;
  font-size: 0.9em;
  color: var(--grey);
  max-height: 5vh;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.2em;
  color: var(--CRELYTICA-DARKEST-BLUE);
}

.feedback-input {
  width: 90%;
  margin-top: 10px;
}

.feedback-submit-button {
  width: 10%;
  margin-top: 10px;
  background-color: var(--CRELYTICA-GREEN);
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}


.definition-toggle {
  cursor: pointer;
  font-weight: bold;
  color: var(--yellow);
  margin-top: 8px;
  width: 100%;
}

.definition-text {
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-left: 3px solid var(--CRELYTICA-DARKEST-BLUE);
  font-weight: normal;
  color: var(--dark-aluminium);
  font-size: 1.1rem;
  text-align: right;

}

.toggle-button {
  color: var(--CRELYTICA-DARKEST-BLUE);
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  transition: color 0.3s ease;
}

.toggle-button:hover {
  color: var(--CRELYTICA-LIGHT-BLUE);
}




/* Styling the submit button */
.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.submit-button:disabled {
  background-color: #ccc; /* Gray out button when disabled */
  cursor: not-allowed;
}

/* Style for the fixed header including the question, checkbox, and button */
.fixed-header {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px;
  z-index: 10; /* Keeps it above the table content */
  border-bottom: 1px solid #ddd; /* Optional: Adds a visual separation between header and table */
}

/* Container for the whole extracted data section */
.extracted-data-container {
  margin-top: 10px;
  max-height: 400px; /* Adjust this to control the height of the scrollable area */
  overflow: hidden;
  border: 1px solid #ddd;
  background-color: #ffffff; /* Optional: Better appearance */
}

/* Container for the scrollable table body */
.extracted-data-table {
  max-height: 400px; /* Set height to make the table body scrollable */
  overflow-y: auto; /* Allow vertical scrolling for the table body */
}

/* Table styles */
.extracted-data-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures columns are of equal width or adjusted to avoid overflow */
}

.extracted-data-table th,
.extracted-data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #fafafa;
  max-width: 150px; /* Limit the maximum width to prevent overflowing cells */
  word-wrap: break-word; /* Break long words onto the next line */
  white-space: normal; /* Allow text to wrap and take up multiple lines */
}

/* Fixed header style for table headers */
.extracted-data-table thead th {
  position: sticky;
  top: 0px; /* Adjust this value based on the fixed header height */
  background-color: #f2f2f2;
  z-index: 5; /* Keep the header above the table rows */
}

.extracted-data-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Optional: Alternating row colors */
}


/* Container for tick and cancel buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px; /* Add space above the buttons if needed */
}

/* Button styles */
.correct-button,
.incorrect-button {
  display: flex;
  align-items: center;
  flex-direction: row; 
  background-color: var(--CRELYTICA-GREEN);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px; /* Optional: rounded corners */
  outline: none; /* Remove default focus outlines */
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.correct-button:hover {
  background-color: var(--highlight-green);
}

.incorrect-button {
  background-color: var(--CRELYTICA-RED);
}

.incorrect-button:hover {
  background-color: var(--highlight-red);
}

/* Add some margin or specific size to make the buttons more distinct */
.correct-button {
  margin-right: 10px; /* Add spacing between buttons if needed */
}





/* Ensure consistent delete button styling */
.delete-group button {
  display: flex;
  align-items: center;
  flex-direction: row; 
  background-color: var(--CRELYTICA-RED); /* or your desired color */
  color: white;
  border: none; /* Remove default button borders */
  padding:8px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px; /* Optional: rounded corners */
  outline: none; /* Remove default focus outlines */
  font-weight: bold;
}

.delete-group button:hover {
  background-color: darkred; /* Darker shade on hover */
}

.delete-group button:focus {
  outline: none; /* Ensure no focus outline appears */
}
