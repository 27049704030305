/* Styles for the loader (spinner) */
.loader {
  height: 10rem;
  width: 10rem;
  animation: spin 2s infinite linear;
  border: 0.3em solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: var(--CRELYTICA-LIGHT-BLUE); /* Spinner color */
  border-radius: 50%;
  margin-bottom: 1rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Wrapper for centering the loader and accompanying text */
.loader-wrapper {
  flex-grow: 1; /* Allows the loader wrapper to grow and fill available space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center elements */
  justify-content: center; /* Vertically center elements */
  text-align: center;
  padding: 20px;
  height: 100%; /* Ensure full height to center elements */
}

/* Customize loading text to match styling */
.loading-text {
  font-size: 1.1rem;
  margin-top: 1rem; /* Add margin to create space between text blocks */
  color: var(--grey);
  z-index: 9500;           /* Ensure it’s on top of everything else */
}

/* Optional: Slight fade in effect for smooth transition */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader-wrapper {
  animation: fadeIn 0.5s ease-in-out;
}

.loader-overlay {
  position: fixed;          /* Stays in place, doesn't expand page height */
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;            /* Covers the whole viewport */
  display: flex;           /* Center the loader both horizontally and vertically */
  align-items: center; 
  justify-content: center;
  z-index: 9000;           /* Ensure it’s on top of everything else */
  background-color: rgba(255, 255, 255, 0.8); /* optional semi-transparent backdrop */
}