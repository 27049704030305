.mobile-nav-bar__container {
  position: fixed;
  display: none;

  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  box-shadow: rgb(90 95 102) 0-1.5px 0 inset;

  z-index: 3000;

  background-color: var(--CRELYTICA-DARKEST-BLUE);
}

.mobile-nav-bar {
  display: flex;
  align-items: center;
  position: relative;
  height: 8rem;
  width: 100%;
  padding: 0.8rem 1.6rem;
  margin: 0 auto;
}

.mobile-nav-bar__brand-container {
  margin-right: auto; /* push brand to the left */
}

.hamburger-container {
  margin: 0 auto;  /* center horizontally */
  width: 48px;     /* or 3rem, or whatever suits your icon’s size */
  /* optionally: max-width: 48px; */
  
  display: flex;
  justify-content: center;
  align-items: center;
}


.mobile-menu-toggle-button {
  font-size: 3.6rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}


.mobile-nav-bar__brand {
  flex: 1;
  display: flex;
  align-items: center;

  height: 100%;

  /* responsive */

  margin-right: 1.6rem;
}

.mobile-nav-bar__link {
  display: flex;
  align-items: center;

  height: 100%;
}

.mobile-nav-bar__logo {
  height: 3.6rem;
}

.mobile-nav-bar__toggle {
  margin-left: 2.4rem;
  font-size: 3.6rem;
  cursor: pointer;
  color: white;
}

.mobile-nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
}

.mobile-nav-bar__tab {
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/

  font-weight: 500;
  font-size: 2rem;
  line-height: 16px;

  padding: 3.2rem 2.4rem;
  background-color: var(--white);
  color: var(--black);
  width: 100%;
  box-shadow: rgb(90 95 102) 0-1.5px 0 inset;
}

.mobile-nav-bar__tab:last-child {
  margin-right: 0;
}

.mobile-nav-bar__tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--CRELYTICA-LIGHT-BLUE);

  /* responsive */

  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.mobile-nav-bar__tab:hover {
  color: var(--white);
}

.mobile-nav-bar__icon {
  display: none;
}

.mobile-nav-bar__menu {
  position: fixed;
  top: 8rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 8rem);
  background-color: var(--aluminium);
  z-index: 300;

  display: flex;
  flex-direction: column;
  justify-content: space-between; /* tabs at top, buttons at bottom */
}

/* This container can grow and become scrollable if needed */
.mobile-nav-bar__menu-content {
  flex: 1;              /* Fill remaining space above the buttons */
  overflow-y: auto;     /* Scroll if content is tall */
}

/* You can keep your existing styles for the buttons */
.mobile-nav-bar__buttons {
  display: flex;
  justify-content: center;
  padding: 3.2rem;
  background-color: var(--CRELYTICA-DARKEST-BLUE);
}

@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar__tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .mobile-nav-bar__container {
    display: flex;
  }

  .mobile-nav-bar__menu--closed {
    display: none;
  }
}