/* General */

.form-container {
  margin: 2rem auto;
  width: 80%;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: var(--CRELYTICA-DARKEST-BLUE);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--white);

  color: var(--CRELYTICA-DARKEST-BLUE);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: var(--CRELYTICA-DARKEST-BLUE);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--CRELYTICA-LIGHT-BLUE);
}

h1,
h2,
h3 {
  margin-top: 1.2rem;
  margin-bottom: 1rem;
}

h4,
h5,
h6 {
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 0.8rem;
}

p {
  margin: 0 0 1.6rem;
  margin-bottom: 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid var(--CRELYTICA-DARKEST-BLUE);

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}

.email-link {
  color: var(--CRELYTICA-DARKEST-BLUE);
}

.error-message {
  color: red;
  margin-top: 20px;
}


.stripe-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-header {
  display: flex;
  justify-content: space-between; /* Aligns children to the start and end of the container */
  align-items: center; /* Vertically aligns items in the middle */
  margin-bottom: 5px; /* Optional: Adds some space between the header and the table */
}

#page-description {
  margin-top: 30;
  margin-bottom: 30;
  flex-grow: 1; /* Allows the text to grow and fill the available space */
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Ensures that overflow text is hidden */
  text-overflow: ellipsis; /* Adds an ellipsis to indicate text overflow */
}

hr {
  border: 0;
  height: 1px;
  background: #333; /* Or any color you prefer */
  margin: 20px 0; /* Adjust the spacing around the <hr> */
}

.hyper-link {
  color: var(--CRELYTICA-LIGHT-BLUE); 
  padding: 5px;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .content-wrapper {
    display: flex;             /* ensure flex is still on */
    flex-direction: column; 
  }
}

.user-type-selection {
  text-align: left;
  margin: 10px 0;
  position: relative; /* This makes sure the buttons are in the normal flow */
}

.user-type-buttons {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 10px;
}

.user-type-button {
  background-color: #f9f9f9;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative; /* Ensure the buttons are positioned normally */
  z-index: 1; /* Lower than the modal but above the content */
}

.user-type-button:hover {
  transform: scale(1.05);
}

.user-type-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.user-type-button span {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}